import React from 'react';

import { graphql } from 'gatsby';
import loadable from '@loadable/component';
import Section from '../Section/Section';
import './PostArchive.scss';

const ArchiveTypes = {
  blog: loadable(() => import('./types/BlogArchive')),
  update: loadable(() => import('./types/UpdateArchive')),
  event: loadable(() => import('./types/EventArchive')),
};

export default function PostArchive({ data, pageContext, archiveNodes, archiveTerms }) {
  const { postsType, withTaxonomies } = data;

  const ComponentName = ArchiveTypes[postsType];
  if (!ComponentName) return null;

  return (
    <Section id="blogItem" class="container">
      <ComponentName
        pageContext={pageContext}
        archiveNodes={archiveNodes}
        archiveTerms={archiveTerms}
        withTaxonomies={withTaxonomies}
      />
    </Section>
  );
}

export const fragments = graphql`
  fragment PostArchive on WpPage_Flexiblelayout_FlexibleChildren_PostArchive {
    postsType
    withTaxonomies
  }

  fragment PostArchive_Section on WpPage_Flexiblelayout_FlexibleChildren_Section_FlexibleChildren_PostArchive {
    postsType
    withTaxonomies
  }
`;
